/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "RcbLpRestApi",
            "endpoint": "https://7c54ijqp7f.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        },
        {
            "name": "RcbPortalSendApplicationEmail",
            "endpoint": "https://p9bopl6fg6.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        },
        {
            "name": "api6ce3fe52",
            "endpoint": "https://f42876jcdf.execute-api.ap-northeast-1.amazonaws.com/master",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://m6ynogzrtbdxdmlyoehbvgedqu.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hhjad7tgpfesjg742qsjuyvbmu"
};


export default awsmobile;
