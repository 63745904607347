<template>
  <footer>
    <div class="section"></div>
    <!-- ▲▲▲ 背景色調整 ▲▲▲ -->
    <section class="section py-6">
      <v-container style="max-width: 640px">
        <v-row>
          <v-col>
            <header class="text-center font-weight-bold">
              <div class="text-h6 font-weight-bold">
                パラダイムシフトの宿泊施設向けソリューション
              </div>
            </header>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-card
              link
              flat
              color="transparent"
              class="text-center"
              :href="rcUrl"
              target="_blank"
            >
              <v-container>
                <v-img
                  src="@/assets/202210/logo_rc.svg"
                  height="73.3"
                  contain
                ></v-img>
                <div class="text-body-2 mt-4">
                  宿泊施設向けマーケティングシステム
                  <v-icon size="20">mdi-open-in-new</v-icon>
                </div>
              </v-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card
              link
              flat
              color="transparent"
              class="text-center"
              :href="afUrl"
              target="_blank"
            >
              <v-container>
                <v-img
                  src="@/assets/202210/logo_af.svg"
                  height="73.3"
                  contain
                ></v-img>
                <div class="text-body-2 mt-4">
                  宿泊施設向けPMS
                  <v-icon size="20">mdi-open-in-new</v-icon>
                </div>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="section py-6 text-center">
      <v-container>
        <v-row>
          <v-col>
            <div>
              <a :href="psUrl" target="_blank">
                <v-img
                  class="d-inline-block"
                  src="@/assets/202210/logo_psinc.svg"
                  width="160"
                ></v-img>
              </a>
            </div>
            <v-btn
              text
              class="text-subtitl-2 font-weight-bold"
              :href="psUrl"
              target="_blank"
            >
              株式会社パラダイムシフト
              <v-icon size="20">mdi-open-in-new</v-icon>
            </v-btn>
            <div class="text-caption">〒103-0003東京都中央区東日本橋1-3-6</div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <p class="text-center text--secondary">
      <small
        >Copyright &copy {{ new Date().getFullYear() }} Paradigmshift Inc. All
        Rights Reserved.</small
      >
    </p>
  </footer>
</template>

<script>
  export default {
    computed: {
      contactUrl() {
        return 'https://contact.psinc.jp'
      },
      rcUrl() {
        return 'https://repchecker.jp'
      },
      afUrl() {
        return 'https://aiface.jp'
      },
      psUrl() {
        return 'https://paradigmshift.io'
      },
    },
  }
</script>
