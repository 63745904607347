import axios from 'axios'
import dayjs from 'dayjs'
window.axios = axios
window.dayjs = dayjs

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Asia/Tokyo")

import { Amplify } from 'aws-amplify'
import awsExports from '@/aws-exports'
Amplify.configure(awsExports)

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
